import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChoizMessagesService {

  @Output() _showMessage: EventEmitter<IChoizMessage> = new EventEmitter<IChoizMessage>();

  message: IChoizMessage;

  constructor() { }

  showMessageOK(type: string, message: string, title: string = '', icon: boolean = false, iconName: string = '', yesno: boolean = false, whatsapp: boolean = true, link: string = null, autoClose: boolean = false, life: number = 1000){
    this.message = {
      type,
      message,
      title,
      icon,
      iconName,
      yesno,
      whatsapp,
      link,
      autoClose,
      life
    }
    this._showMessage.emit(this.message);
  }

  showMessage({type, message, title = '', icon = false, iconName = '', yesno = false, whatsapp = true, link = null, autoClose = false, life = 1000}){
    this.message = {
      type,
      message,
      title,
      icon,
      iconName,
      yesno,
      whatsapp,
      link,
      autoClose,
      life
    }
    this._showMessage.emit(this.message);
  }
}

export interface IChoizMessage {
  type: string;
  message: string;
  title: string;
  icon: boolean;
  iconName: string;
  yesno: boolean;
  whatsapp: boolean;
  link: string
  autoClose: boolean
  life: number
}