import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject, signal } from '@angular/core';
import { xApiKeyMyAccount } from '@core/interceptors/auth.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private http = inject(HttpClient);
  private cookieService = inject(CookieService);
  private router = inject(Router);

  endpoint: string = 'pharmacy/signin';
  // Endpoints de myAccount
  private loginEndpoint: string = 'api/credentials/signin';
  private verificationEndpoint = 'api/credentials/validate_token/get_credential';
  private forgotPasswordEndpoint = 'api/credentials/forgot/password/user';
  private resetPasswordEndpoint = 'api/credentials/reset/password';

  public isAdmin  = signal<boolean>(false); 
  public isDoctor = signal<boolean>(false); 
  public isPharma = signal<boolean>(false); 

  user: any;
  roleUser: string[] = [];

  constructor(
    @Inject('LOCALSTORAGE') private localStorage: Storage
  ) { }

  login(form): Observable<any> {
    return this.http.post(`${environment.apiUrl}/${this.endpoint}`, { userName: form.userName, password: form.password })
        .pipe(
          map((response: any) => {
          this.cookieService.set('token', response.accessToken, { expires: 1, sameSite: 'None', secure: true, path: '/'});
          this.setUserInfo(response);
          this.getCurrentUser();
          return response;
        }));
  }

  logout(): void {
    // Borrar token y usuario del localstorage
    this.cookieService.delete('token');
    const logoutRelogin = this.localStorage.getItem('currentUser');
    this.localStorage.setItem('lastUser', logoutRelogin);
    this.localStorage.removeItem('currentUser');
  }

  relogin(token: string, user: any){
    this.cookieService.set('token', token);
    this.validateTokenReLogin().subscribe(data => {
      const lastUser = this.localStorage.getItem('lastUser');
      this.localStorage.setItem('currentUser', lastUser);
      this.router.navigateByUrl('/')
    });
  }

  getCurrentUser(){
    const encryptedValue = this.localStorage.getItem('currentUser') || null;
    if(encryptedValue){
      this.user = JSON.parse(unescape(atob(encryptedValue)));      
      this.validateToken2().subscribe(resp => {        
        if(resp == false){
          this.logout();
        }
      })
    } else {
      this.user = this.localStorage.getItem('currentUser');
    }
    this.setRole();
    return this.user;
  }

  setRole(){
    if(this.user.role.includes('ROLE_ADMIN')){
      this.isAdmin.set(true);
    }
    if(this.user.role.includes('ROLE_DOCTOR_USER')){
      this.isDoctor.set(true);
    }
    if(this.user.role.includes('ROLE_PHARMACY_USER')){
      this.isPharma.set(true);
    }
  }

  getUserRole(){
    if(this.user){
      this.roleUser = this.user.role;
    }
    return this.roleUser;
  }

  // Guarda el token del usuario en las cookies al momento de iniciar sesion
  async setUserInfoReLogin(user: any){
    let value = {
      token: user.token,
      role: user.role,
      email: user.email,
      alias: user.alias,
      fullName: user.fullName
    }
    const encryptedValue = btoa(escape(JSON.stringify(value)));
    this.localStorage.setItem('currentUser', encryptedValue);
    return encryptedValue;
  }

  async setUserInfo(response: any){
    let nameUnFormated: string = response.username.split('@')[0];
    let nameFormated: string = `${nameUnFormated.charAt(0).toUpperCase()}${nameUnFormated.substring(1, nameUnFormated.length)}`;
    let value = {
      token: response.accessToken,
      role: response.roles,
      email: response.username,
      alias: nameFormated,
      fullName: response.username
    }
    //this.changeThemeForRole(response.roles[0]);
    const encryptedValue = btoa(escape(JSON.stringify(value)));
    this.localStorage.setItem('currentUser', encryptedValue);
    return encryptedValue;
  }

  async isLoggedIn(){   
    await this.getCurrentUser();
    return this.user ? true : false;
  }

  validateToken2(){
    return this.http.get(`${environment.apiMyAccountUrl}/${this.verificationEndpoint}`, {
      context: xApiKeyMyAccount()
    }).pipe(
      map(data => { return true }),
      catchError(err => { return of(false) })
    )
  }

  validateToken(): Observable<boolean> {
    //Verifica que el token sea valido
    if(this.cookieService.check('token')){
      return this.http.get(`${environment.apiMyAccountUrl}/${this.verificationEndpoint}`, {
        context: xApiKeyMyAccount()
      }).pipe(
        map(data => { return true }),
        catchError(err => { return of(false) })
      )
    } else {
      return of(false);
    }
  }

  validateTokenReLogin(){
    return this.http.get(`${environment.apiMyAccountUrl}/${this.verificationEndpoint}`, {
      context: xApiKeyMyAccount()
    }).pipe(
      map(data => { return data }),
      catchError(err => { return of(false) })
    )
  }


}
