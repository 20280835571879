import { SocketIoConfig } from "ngx-socket-io";

const config: SocketIoConfig = { url: 'https://ui-apibo-mx-dev.choiz.com.mx', options: {} };

export const environment = {
  production: false,
  environment: 'develop',
  apiUrl: 'https://choiz-core-mx-dev.choiz.com.mx',
  webApiUrl: 'https://ui-webapi-backend-mx-dev.choiz.com.mx',
  apiMyAccountUrl: 'https://my-account-core-mx-dev.choiz.com.mx',
  xApiKeyCore: 'api-form',
  xApiKeyMyAccount: 'xUApeaz3fMI7E0tNfG4H1xzrcY8pWaGJnb7lDCGQvPQd',
  socketConfig: config,
  postalCodeApiUrl: 'https://ui-apibo-mx-dev.choiz.com.mx',
  googleApiKey: 'AIzaSyBqY8IOjtSbW5Da3dohqhPmLzW1lH_NlKc',
};
