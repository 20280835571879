import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Injectable, NgZone, inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpContextToken, HttpContext } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '@auth/services/auth.service';
import { environment } from '@environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ChoizMessagesService } from '@shared/components/choiz-messages/choiz-messages.service';

const API_KEY = new HttpContextToken<boolean>(() => false);
const IMAGE = new HttpContextToken<boolean>(() => false);
const RESET_TOKEN = new HttpContextToken<boolean>(() => false);
const CHATIMAGE = new HttpContextToken<boolean>(() => false);
const DIRECTIONS = new HttpContextToken<boolean>(() => false);
const CLIENTS = new HttpContextToken<boolean>(() => false);

export function xApiKeyMyAccount() {
  return new HttpContext().set(API_KEY, true);
}
export function xImageMyAccount() {
  return new HttpContext().set(IMAGE, true);
}
export function xResetToken() {
  return new HttpContext().set(RESET_TOKEN, true);
}
export function xChatImageMyAccount() {
  return new HttpContext().set(CHATIMAGE, true);
}
export function xGoogle() {
    return new HttpContext().set(DIRECTIONS, true);
}
export function xClient() {
    return new HttpContext().set(CLIENTS, true);
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private authService = inject(AuthService);
    private router = inject(Router);
    private cookieService = inject(CookieService);
    private _cms = inject(ChoizMessagesService);
    private zone = inject(NgZone);
    private _loader = inject(NgxUiLoaderService);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let headers: HttpHeaders;
        if(req.context.get(API_KEY)) {
            headers = new HttpHeaders({
                'Authorization': `Bearer ${this.cookieService.get('token')}`, //'Bearer ' + user.token
                'Content-Type': 'application/json',
                'x-api-key': `${environment.xApiKeyMyAccount}`, //'api-my-account',
                'email': `${req.headers.get('email')}`,
                'file': `${req.headers.get('file')}`,
                'app-id': 'my-account'
            });
        }
        else if(req.context.get(RESET_TOKEN)){
            headers = new HttpHeaders({
                'Authorization': `Bearer ${this.cookieService.get('token')}`, //'Bearer ' + user.token
                'x-api-key': `${environment.xApiKeyMyAccount}`, //'api-my-account',
                'email': `${req.headers.get('email')}`,
                'reset_token': `${req.headers.get('resetToken')}`,
            });
        }
        else if(req.context.get(IMAGE)){
            headers = new HttpHeaders({
                'Authorization': `Bearer ${this.cookieService.get('token')}`, //'Bearer ' + user.token
                'x-api-key': `${environment.xApiKeyMyAccount}`, //'api-my-account',
                'file-name': `${req.headers.get('file-name')}`,
                'bucket-folder-name': `${req.headers.get('bucket-folder-name')}`
            });
        }
        else if(req.context.get(CHATIMAGE)){
            headers = new HttpHeaders({
                'Authorization': `Bearer ${this.cookieService.get('token')}`, //'Bearer ' + user.token
                'x-api-key': `${environment.xApiKeyMyAccount}`, //'api-my-account',
                'file': `application/octet-stream`,
                'file-name': `${req.headers.get('file-name')}`,
            });
        }
        else if (req.context.get(CLIENTS)) {
            headers = new HttpHeaders({
              'Authorization': `Bearer ${this.cookieService.get('token')}`,
              'Content-Type': 'application/json',
              'user-name': `${req.headers.get('userName')}`,
              'x-api-key': `${environment.xApiKeyCore}`,
            });
        }
        else if (req.context.get(DIRECTIONS)) {
            headers = new HttpHeaders({

            });
        }
        else {
            headers = new HttpHeaders({
                'Authorization': `Bearer ${this.cookieService.get('token')}`,
                'Content-Type': 'application/json',
                'x-api-key': `${environment.xApiKeyCore}`,
            });
        }

        const cloned = req.clone({headers});
        return next.handle(cloned).pipe(catchError(error => this.errorHandler(error)));
    }


    errorHandler(error: HttpErrorResponse){

        const mapError = {
            500: () => {
                this._cms.showMessage({ type: 'error', message: 'Por favor, vuelva a intentar nuevamente!', title: 'Error', whatsapp: false, autoClose: true, life: 3000});
            },
            409: () => {
                this._cms.showMessage({ type: 'error', message: 'Por favor, vuelva a intentar nuevamente!', title: 'Error', whatsapp: false, autoClose: true, life: 3000});
            },
            400: () => {
                this._cms.showMessage({ type: 'error', message: error.error.error[0], title: 'Error', whatsapp: false, autoClose: true, life: 3000});
            },
            401: () => {
                this.zone.run(() => {
                    this.router.navigateByUrl('/auth/entrar');
                });
                error.error.error.map(e => {
                    this._cms.showMessage({ type: 'error', message: error.error.error[0], title: 'Error', whatsapp: false, autoClose: true, life: 3000});
                })
            },
            403: () => {
                this._cms.showMessage({ type: 'error', message: error.error.error[0], title: 'Error', whatsapp: false, autoClose: true, life: 3000 });
            },
            404: () => {
                this._cms.showMessage({ type: 'error', message: error.error.error[0], title: 'Error', whatsapp: false, autoClose: true, life: 3000 });
            }
        }
       mapError[error.status] ? mapError[error.status]() : mapError[500];
       return throwError(() =>error.error);
    }
}
